const CartActionTypes = {
  TOGGLE_CART_HIDDEN: "TOGGLE_CART_HIDDEN",
  ADD_ITEM_TO_CART: "ADD_ITEM_TO_CART",
  REMOVE_ITEM_FROM_CART: "REMOVE_ITEM_FROM_CART",
  CLEAR_ITEM_FROM_CART: "CLEAR_ITEM_FROM_CART",
  CLEAR_CART: "CLEAR_CART",
  FETCH_CART_FROM_FIREBASE: "FETCH_CART_FROM_FIREBASE",
  SET_CART_FROM_FIREBASE: "SET_CART_FROM_FIREBASE",
  UPDATE_CART_IN_FIREBASE: "UPDATE_CART_IN_FIREBASE",
};

export default CartActionTypes;
