const UserActionTypes = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  GOOGLE_SIGN_IN_START: "GOOGLE_SIGN_IN_START",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  EMAIL_SIGN_IN_START: "EMAIL_SIGN_IN_START",
  CHECK_USER_SESSION: "CHECK_USER_SESSION",
  CHECK_USER_SESSION_NO_USER: "CHECK_USER_SESSION_NO_USER",
  SIGN_OUT_START: "SIGN_OUT_START",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",
  SIGN_UP_START: "SIGN_UP_START",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
  ADD_FAVORITE_START: "ADD_FAVORITE_START",
  ADD_FAVORITE_SUCCESS: "ADD_FAVORITE_SUCCESS",
  ADD_FAVORITE_FAILURE: "ADD_FAVORITE_FAILURE",
  REMOVE_FAVORITE_START: "REMOVE_FAVORITE_START",
  REMOVE_FAVORITE_SUCCESS: "REMOVE_FAVORITE_SUCCESS",
  REMOVE_FAVORITE_FAILURE: "REMOVE_FAVORITE_FAILURE",
  CLEAR_ERROR: "CLEAR_ERROR",
  SET_ERROR: "SET_ERROR",
};

export default UserActionTypes;
